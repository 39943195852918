import { Injectable } from '@angular/core';
import { SessionKey } from '../enums/session-key.enum';
import { AppStoreService } from '../store/app-store.service';
import { Token } from '../models/token.model';
import { jwtDecode } from 'jwt-decode';
import { StorageType } from '../enums/storage-type.enum';
import { unixTimeStamp } from '../utils/date-time';
import { ConfigService } from '../../config/config.service';

/**
 * Session kezelését végrehajtó osztály.
 * A nyelvi beállítás mindig mindig localStorage-ban, az infoblock mindig a sessionStorage-ben van eltárolva.
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(
    private readonly _appStoreService: AppStoreService,
    private readonly _configService: ConfigService,
  ) {}

  /**
   * Visszaadja egy eltárolt elem értékét, a paraméreben kapott kulcs alapján
   * @param {string} key A tárolt elem kulcsa
   * @returns {sring}
   */
  getItem(key: string, storageType?: StorageType): string | null {
    return key ? this.getStorage(storageType).getItem(key) : null;
  }

  /**
   * Tárol egy elemet a storage-ban, a paraméterben kapott kulcs-érték alapján
   * @param {string} key A tárolandó elem kulcsa
   * @param {string} value A tárolandó elem értéke
   */
  setItem(key: string, value: string, storageType?: StorageType): void {
    if (key && value) {
      this.getStorage(storageType).setItem(key, value);
    }
  }

  /**
   * A kulcs alapján eltávolítja az elemet a storage-ból
   * @param {string} key A tárolt elem kulcsa
   */
  removeItem(key: string, storageType?: StorageType): void {
    if (key) {
      this.getStorage(storageType).removeItem(key);
    }
  }

  /**
   * Törli a storage-t
   */
  clear(): void {
    this.getStorage(StorageType.LOCAL).clear();
    this.getStorage(StorageType.SESSION).clear();
  }

  startSession(): void {
    const storageValue = this.getItem(SessionKey.TOKEN);
    if (storageValue) {
      try {
        let token: Token = jwtDecode<any>(storageValue);

        if (token != null && !(token.exp > unixTimeStamp())) {
          console.log('token expired');
        } else {
          token.token = storageValue;
          this._appStoreService.setToken(token);
        }
      } catch (error) {
        console.dir(error);
      }
    }
  }

  private getStorage(storageType?: StorageType): Storage {
    if (storageType) {
      return storageType === StorageType.LOCAL ? localStorage : sessionStorage;
    }
    return this._configService.configData.session.defaultStorageType === StorageType.LOCAL
      ? localStorage
      : sessionStorage;
  }
}
