import { default as moment, Moment } from 'moment';
import { DurationInputArg1, DurationInputArg2 } from 'moment';

/**
 *
 * @param value
 * @param dateFormat
 */
export function formatDate(
  value: string | number | Date | Moment,
  dateFormat: string
): string {
  const s: string = moment(value).format(dateFormat);
  return s;
}

export function currentDate(dateFormat?: string): string {
  return dateFormat ? moment().format(dateFormat) : moment().format();
}

export function date2String(date: Date, dateFormat: string): string {
  return moment(date).format(dateFormat);
}

// with milliseconds
export function timeStamp(value?: string): number {
  return value ? moment(value).valueOf() : moment().valueOf();
}

// without milliseconds
export function unixTimeStamp(value?: string): number {
  return value ? moment(value).unix().valueOf() : moment().unix().valueOf();
}

export function timeStamp2Date(value: number): Date {
  return moment(value).toDate();
}

export function dateString2Date(dateString: string, dateFormat: string): Date {
  const momentObj = moment(dateString, dateFormat);
  return moment(momentObj).toDate();
}

/**
 * Hozzáadja az aktuális dátumhoz, a value paraméterben átadott értéket a type paraméterben meghatározott
 * dátum részhez.
 * A funkció a momentjs add függvényét alkalmazza.
 * @param {number} value Mennyit adjon hozzá az aktuális dátumhoz
 * @param {string} type A dátum melyik részéhez adja hozzá a value paraméterben szereplő értéket
 * @returns {number} A visszaadott érték a manipulált dátum, unix timestamp milliseconds formátumban
 */
export function dateAdd(
  value: DurationInputArg1,
  unit: DurationInputArg2
): number {
  return moment().add(value, unit).valueOf();
}

export function getMoment(value: string | Date): Moment {
  return moment(value);
}

export function formatMomentDate(obj: Moment, dateFormat: string): string {
  return obj.format(dateFormat);
}

export function momentToDate(obj: Moment): Date {
  return moment(obj).toDate();
}

/**
 * Másodpercből óra:perc:másodperc formátumot ad vissza. Visszaszámlálókhoz.
 * Ha a másodpercek száma nem tesz ki egy órát, akkor a 0 óra nem jelenik meg a szövegben.
 * A perc és mádodperc mindig két számjegyű (pl.: 01:01).
 *
 * @param {number} duration  Másodperc.
 * @returns {string}  Óra:perc:másodperc formátumú szöveg.
 */
export function formatTime(duration: number): string {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':';
  }

  ret += (mins > 10 ? '' : '0') + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
}
