import { Injectable } from '@angular/core';
import { config } from './config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData!: any;

  constructor() {
    this.setConfigData();
  }

  setConfigData() { 
    this.configData = config;
  }
}
